import React, {FC} from 'react';
import Dialog from "@mui/material/Dialog";
import {IImportTasks} from "../../Models/IImportTasks";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {SubmitHandler, useForm} from "react-hook-form";
import {Button, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import {IGroup} from "../../Models/IGroup";
import CustomersService from "../../Service/CustomersService";
import Errorhandler from "../../errorHendler";

interface NewGroupsDialogInterface {
    open:boolean
    handleClose:()=>void
    handleCreate:(customer:IGroup)=>void
}
type InputGroup={
    name:string
    description:string
}
const NewGroupsDialog:FC<NewGroupsDialogInterface> = (props) => {
    const { register, handleSubmit,
         formState: { errors },} = useForm<InputGroup>();

    const onSubmit: SubmitHandler<InputGroup> = async (data) =>{
        try{
            const res=await CustomersService.createGroups(data.name,data.description)
            props.handleCreate(res.data)
        }catch (e){
            alert(Errorhandler.errorMessage(e))
        }
    };


    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Новая группа</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <DialogContent>
                    <DialogContentText>
                        Создает новую группу
                    </DialogContentText>
                    <TextField
                        label="Название рассылки"
                        variant="outlined"
                        fullWidth={true}
                        sx={{ m: 1 }}
                        error={errors.name?.message!=undefined}
                        helperText={errors.name?.message??undefined}
                        {...register('name',{required:'Заполните Название'})}
                    />
                    <TextField
                        label="Тема сообщения"
                        variant="outlined"
                        fullWidth={true}
                        sx={{ m: 1,}}
                        {...register('description',{required:'Заполните описание'})}
                        error={errors.description?.message!=undefined}
                        helperText={errors.description?.message??undefined}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type={"submit"}>
                        Добавить
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default NewGroupsDialog;