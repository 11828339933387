import {AxiosResponse} from "axios";
import {IUser} from "../Models/IUser";
import $api from "../http";
import {ISender} from "../Models/ISender";
import {CustomEmailDto} from "../Models/dto/custom-email.dto";
import {ILetters} from "../Models/dto/ILetters";
import {ISenderProfile} from "../Models/ISenderProfile";
import {IRecipients} from "../Models/IRecipients";
import {SendForGroup} from "../Models/dto/SendForGroup";
import {ISenderPackShort} from "../Models/ISenderPackShort";

export default class EmailManagerService{
    static async fetchSenderList():Promise<AxiosResponse<ISender[]>>{
        return $api.get('/email-manager/fetchProfiles');
    }
    static async fetchLetters(page:number):Promise<AxiosResponse<ILetters[]>>{
        return $api.get<ILetters[]>(`/email-manager/fetchLetters?page=${page}`);
    }
    static async fetchSenderPack(page:number):Promise<AxiosResponse<ISenderPackShort[]>>{
        return $api.get<ISenderPackShort[]>(`/sender-manager/fetchSendersPack/${page}`);
    }
    static async sendCustomEmail(dto:CustomEmailDto,attachment:File[]):Promise<AxiosResponse>{
        let formData = new FormData();

        attachment.forEach(e=>{
            formData.append("attachment", e);
        })
        formData.append("subject", dto.subject);
        formData.append("name", dto.name);
        formData.append("htmlMessage", dto.htmlMessage);
        formData.append("dateSend", dto.dateSend);
        formData.append("senderId", dto.senderId.toString());
        dto.users.forEach(e=>{
            formData.append("users[]", e.toString());
        })

        dto.sendSettings.forEach((e,i)=>{
            formData.append(`sendSettings[${i}]`, JSON.stringify(e));
        })


        return $api.post('/email-manager/sendOne',formData,{
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    }

    static async fetchSendProfile():Promise<AxiosResponse<ISenderProfile[]>>{
        return $api.get<ISenderProfile[]>(`/sender-manager/sender-profile`);
    }
    static async fetchRecipients():Promise<AxiosResponse<IRecipients[]>>{
        return $api.get<IRecipients[]>(`/customers/fetchGroupWithCount`);
    }


    static async sendCustomEmailForGroup(dto:SendForGroup,attachment:File[]):Promise<AxiosResponse>{
        let formData = new FormData();

        attachment.forEach(e=>{
            formData.append("attachment", e);
        })
        formData.append("subject", dto.subject);
        formData.append("isArticle", (dto.isArticle)?'1':'0');
        formData.append("name", dto.name);
        formData.append("articleText", dto.articleText);
        formData.append("htmlMessage", dto.htmlMessage);
        formData.append("dateSend", dto.dateSend);
        formData.append("sendSettings[]", '');
        formData.append("sendersId[]", dto.senderId.toString());
        dto.groupsId.forEach(e=>{
            formData.append("groupsId[]", e.toString());
        })


        dto.sendSettings.forEach((e,i)=>{
            formData.append(`sendSettings[${i}]`, JSON.stringify(e));
        })

        return $api.post('/sender-manager/createPackForGroups',formData,{
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    }
    static async uploadImage(image:File){
        let formData = new FormData();
        formData.append("file", image);
        return $api.post('/files/upload',formData,{
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })

    }
}