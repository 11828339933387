import {AxiosResponse} from "axios";
import $api from "../http";
import {ICustomer} from "../Models/ICustomer";
import {IGroup} from "../Models/IGroup";
import {ICustomerData} from "../Models/ICustomerData";
import {IImportTasks} from "../Models/IImportTasks";

export default class CustomersService{
    static async fetchCustomers(page:number,q?:string):Promise<AxiosResponse<ICustomer[]>>{
        return $api.get(`/customers/customer?page=${page}${(q!==undefined)?`&q=${q}`:``}`,);
    }
    static async createCustomers(data:any):Promise<AxiosResponse<ICustomer>>{
        return $api.post(`/customers/customer`,data);
    }
    static async openCustomer(id:Number):Promise<AxiosResponse<ICustomer>>{
        return $api.get(`/customers/customer/${id}`,);
    }
    static async fetchGroups():Promise<AxiosResponse<IGroup[]>>{
        return $api.get(`/customers/group`,);
    }
    static async createGroups(name:string,description:string):Promise<AxiosResponse<IGroup>>{
        return $api.post(`/customers/group`,{description:description,name:name,color:'#4287f5'});
    }
    static async fetchAllData(id:number):Promise<AxiosResponse<ICustomerData>>{
        return $api.get(`/customers/customerData/${id}`,);
    }
    static async deleteUserFromGroup(idGroup:number,idCustomer:number):Promise<AxiosResponse>{
        return $api.delete(`/customers/changeGroup/${idGroup}/${idCustomer}`,);
    }
    static async customerCount():Promise<AxiosResponse<number>>{
        return $api.get(`/customers/customer-count`,);
    }
    static async fetchImportTasks(page:number):Promise<AxiosResponse<IImportTasks[]>>{
        return $api.get(`/customers/fetch-import/${page}`,);
    }
    static async createImportTasks(file:File,groups:number[]):Promise<AxiosResponse<IImportTasks>>{
        let formData = new FormData();
        formData.append("file", file);
        groups.forEach(g=>{
            formData.append("groups[]", g.toString());
        })

        return $api.post(`/customers/saveExcel`,formData,{
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }
}