import React, {useEffect, useState} from 'react';
import MainComponent from '../Components/MainComponent';
import TitleRow from "../Components/Title";
import {
    Box,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {IImportTasks} from "../Models/IImportTasks";
import CustomersService from "../Service/CustomersService";
import Errorhandler from "../errorHendler";
import NewImportTaskDialog from "../Components/Dialogs/NewImportTaskDialog";

const ImportCustomer = () => {
    const [tasks,setTasks]=useState<IImportTasks[]>([])
    const [page,setPage]=useState(0)
    const [show,setShow]=useState(false)
    useEffect(()=>{
        fetchTasks().then()
    },[])
    const fetchTasks = async () => {
      try{
          const tasks=await CustomersService.fetchImportTasks(page)
          if(tasks.data.length>0){
              setTasks(prevState => ([...prevState,...tasks.data]))
              setPage(prevState => (prevState+1))
          }
      }catch (e){
          alert(Errorhandler.errorMessage(e))
      }
    }

    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Импорт списка клиентов`} actions={
                    <Stack direction={"row"}>
                        <Button variant="contained" onClick={()=>{setShow(true)}}>Новый импорт</Button>
                    </Stack>
                }/>
            </React.Fragment>
            <Paper>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TableContainer sx={{ maxHeight: '100vp' }}>
                        <Table stickyHeader aria-label="collapsible sticky  table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Файл</TableCell>
                                    <TableCell align="right">Всего получателей</TableCell>
                                    <TableCell align="right">Успешно добавлено</TableCell>
                                    <TableCell align="right">Ошибка</TableCell>
                                    <TableCell align="right">Дубликаты</TableCell>
                                    <TableCell align="right">Статус</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    tasks.map(e=>(
                                        <TableRow key={e.id}>
                                            <TableCell />
                                            <TableCell><a href={e.fileUrl} target={'_blank'}>Открыть файл</a></TableCell>
                                            <TableCell align="right">{e.countUser}</TableCell>
                                            <TableCell align="right">{e.sussesEmail}</TableCell>
                                            <TableCell align="right">{e.errorEmail}</TableCell>
                                            <TableCell align="right">{e.duplicateEmail}</TableCell>
                                            <TableCell align="right">{(e.idDone)?'Завершено':'В работе'}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
            <NewImportTaskDialog handleClose={()=>setShow(false)} open={show} handleCreate={(task)=>{
                setTasks(prevState => ([task,...tasks]))
                setShow(false)
            }}/>
        </MainComponent>
    );
};

export default ImportCustomer;