import React, {FC, PropsWithChildren} from 'react';
import MainComponent from "../../Components/MainComponent";
import TitleRow from "../../Components/Title";
import {Box, Stack, Step, StepLabel, Stepper, } from "@mui/material";
import {NewEmailProvider} from "../../Providers/NewEmailContext";

const NewEmailPageV3:FC<PropsWithChildren<unknown>> = ({children}) => {
    return (
        <NewEmailProvider>
            <MainComponent>
                <React.Fragment>
                    <TitleRow title={`Новая рассылка`} actions={
                        <Stack direction={"row"}>
                        </Stack>
                    }/>
                </React.Fragment>
                <Box sx={{ width: '100%', height: '100%' ,minHeight:'100%'}} bgcolor="background.paper">
                    {children}
                </Box>
            </MainComponent>
        </NewEmailProvider>
    );
};

export default NewEmailPageV3;