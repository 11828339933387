import React, {FC} from 'react';
import Parser from 'html-react-parser';
import {Button, Grid, Paper, Typography} from "@mui/material";
import {NewEmailPageState} from "../../Models/dto/NewEmailPageState";
import EmailManagerService from "../../Service/EmailManagerService";
import Errorhandler from "../../errorHendler";
import {useNavigate} from "react-router-dom";
import {RouteNames} from "../../Routes";


const TotalData:FC<NewEmailPageState> = (props) => {
    const navigate=useNavigate();

    const send = async () => {
        console.log(props.isArticle)
      try{
          const res=await EmailManagerService.sendCustomEmailForGroup({
              groupsId:props.recipients,
              subject:props.subject,
              name:props.name,
              htmlMessage:props.messageBody,
              dateSend:`${new Date().toISOString()}`,
              senderId:props.sendersId,
              sendSettings:[],
              isArticle:props.isArticle,
              articleText:props.articleText

          },props.file)
          navigate(RouteNames.LETTERS);
      }catch (e){
          alert(Errorhandler.errorMessage(e))
      }
    }

    return (
        <div>
            <Grid container spacing={2} >
                <Grid item  xs={6} sx={{m:3}}>
                    <Paper variant="outlined">
                        <Typography variant={'h6'}>О рассылке</Typography>
                        <Typography variant={'subtitle1'}>Тема сообщения: {props.subject}</Typography>
                        <Typography variant={'subtitle1'}>Выбрано групп: {props.recipients.length}</Typography>
                        <Button variant="contained" onClick={send}>Отправить</Button>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    {Parser(props.messageBody)}
                </Grid>
            </Grid>
        </div>
    );
};

export default TotalData;