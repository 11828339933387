import React, {FC, useEffect, useState} from 'react';
import MainComponent from "../Components/MainComponent";
import TitleRow from "../Components/Title";
import {
    Avatar,
    Button,
    CircularProgress, Collapse, Divider,
    Grid,
    ListItem,
    ListItemAvatar,
    Paper,
    Stack,
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography
} from "@mui/material";
import CustomersService from "../Service/CustomersService";
import Errorhandler from "../errorHendler";
import {styled} from "@mui/material/styles";
import {ICustomerData, SenderPack} from "../Models/ICustomerData";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ImageIcon from '@mui/icons-material/Image';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";


interface CustomerDetailState{
    loading:boolean
    data?:ICustomerData
    errorText?:string
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

interface CollapseListItemProps{
    e: SenderPack
    index: number
}
const CollapseListItem:FC<CollapseListItemProps> = (props) => {
    const [ open, setOpen ] = useState(false)
    const handleClick = () => {
        setOpen(!open)
    }
    return <div onClick={handleClick}>
        <ListItem key={`sender_${props.index}`}>
            <ListItemText primary={props.e.name} secondary={props.e.dataStart.toString()} />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
            key={`sender_collapse_${props.index}`}
            in={open}
            timeout='auto'
            unmountOnExit
        >
            <Typography variant={'subtitle1'}>Отправленные сообщения</Typography>
            <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
                {
                    props.e.sendMails.map((mail,index)=>
                        <ListItem key={`sender_mail_${index}`}>
                            <ListItemText primary={`Отправка ${mail.createdAt} - Просмотров ${mail.views.length}`} secondary={mail.response} />
                    </ListItem>
                    )
                }
            </List>
        </Collapse>
        <Divider />
    </div>


}

const CustomerDetailPage = () => {
    const query = new URLSearchParams(window.location.search);
    const [state,setState]=useState<CustomerDetailState>({loading:true,data:undefined})

    useEffect(()=>{
        setState(prevState => ({...prevState,loading:true,errorText:undefined,data:undefined}))

        if(query.get('id')==null){
            setState(prevState => ({...prevState,loading:false,errorText:'Ошибка в id Компании',data:undefined}))
            return
        }
        CustomersService.fetchAllData(Number(query.get('id'))).then(value=>{
            setState(prevState => ({...prevState,loading:false,data:value.data}))
        }).catch(e=>{
            alert(Errorhandler.errorMessage(e))
            setState(prevState => ({...prevState,loading:false,errorText:e.toString(),data:undefined}))
        })
    },[query.get('id')])

    const deleteGroup =async (id:number) => {
        try{
            const res=await CustomersService.deleteUserFromGroup(id,state.data!.id);
            setState(prevState => {
                if(prevState.data!==undefined){
                    const groups=prevState.data?.groups.filter(e=>e.id!==id)
                    const data={
                        ...prevState.data,
                        groups:groups
                    }
                    return {...prevState,data:data}
                }else{
                    return  {...prevState}
                }

            })
        }catch (e){
            alert(Errorhandler.errorMessage(e))

        }
    }


    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`О клиенте`} actions={
                    <Stack direction={"row"}>
                    </Stack>
                }/>
            </React.Fragment>
            {
                (state.loading)&&<CircularProgress />
            }
            {
                (state.data!=undefined)&&<Grid container spacing={2} mt={2}>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant={'h6'}>Общая информация</Typography>
                            <Typography variant={'subtitle1'}>Email <a href={`mailto:${state?.data.email}}`}>{state?.data.email}</a></Typography>
                            <Typography variant={'subtitle1'}>Имя {state?.data.name}</Typography>
                            <Typography variant={'subtitle1'}>Группы:</Typography>
                            <Table  aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Название</TableCell>
                                        <TableCell>Описание</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        state?.data.groups.map(group=>(
                                            <TableRow
                                                key={group.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {group.name}
                                                </TableCell>
                                                <TableCell >{group.description}</TableCell>
                                                <TableCell align="right">
                                                    {
                                                        (group.id!==1)&&<IconButton aria-label="delete" onClick={()=>deleteGroup(group.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant={'h6'}>Рассылки клиента</Typography>
                            <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
                                {
                                    state.data.senderPacks.map((e,index)=>
                                        <CollapseListItem e={e} index={index}/>
                                    )
                                }
                            </List>
                        </Item>
                    </Grid>
                </Grid>
            }
            {
                (state.errorText!=undefined)&&<Typography>{state.errorText}</Typography>
            }

        </MainComponent>
        );
};

export default CustomerDetailPage;