import React from 'react';
import NewEmailPageV3 from "./NewEmailPageV3";

const NewEmailStep3 = () => {
    return (
        <NewEmailPageV3>
            
        </NewEmailPageV3>
    );
};

export default NewEmailStep3;