import React from 'react';
import {Box, Button, TextField, Typography} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";

type LoginInputs = {
    reason: string,
};
const UnsubscribePage = () => {
    const { register,
        handleSubmit, formState: { errors } } = useForm<LoginInputs>();

    const onSubmit: SubmitHandler<LoginInputs> = async (data) => {

    };
    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate  sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Typography variant="h4" gutterBottom>
                Отписка от информационной рассылки
            </Typography>
            <Typography variant="body1" gutterBottom>
                Расскажите почему вы не хотите больше получать наши письма?
            </Typography>
            <TextField
                id="standard-basic"
                label="Почему вы хотите отписаться?"
                variant="standard"
                {...register('reason')}
                rows={5}
            />
            <Button variant="contained" type={"submit"}>Отписаться</Button>
        </Box>
    );
};

export default UnsubscribePage;