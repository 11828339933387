import React from 'react';
import TitleRow from "../Components/Title";
import {Box, Button, Grid, Paper, Stack, Typography} from "@mui/material";
import {RouteNames} from "../Routes";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {format} from "date-fns";
import MainComponent from "../Components/MainComponent";

const UsersPage = () => {
    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Рассылки`} actions={
                    <Stack direction={"row"}>
                        <Button variant="contained" onClick={()=>{}}>Новый рассылка</Button>
                    </Stack>
                }/>
            </React.Fragment>

            <Box sx={{ width: '100%', typography: 'body1' }}>

            </Box>

        </MainComponent>
    );
};

export default UsersPage;