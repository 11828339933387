import React from 'react';
import NewEmailPageV3 from "./NewEmailPageV3";

const NewEmailResult= () => {
    return (
        <NewEmailPageV3>
            
        </NewEmailPageV3>
    );
};

export default NewEmailResult;