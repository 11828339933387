import axios, {AxiosError} from "axios";

export default class Errorhandler{
    static errorMessage(e:any){
        if (axios.isAxiosError(e)) {
            const serverError = e as AxiosError;
            if (serverError && serverError.response) {
                return `${JSON.stringify(serverError.response.data)} ${serverError.response.status}`;
            }
        }
        try{
            const serverError = e as AxiosError;
            if (serverError && serverError.response) {
                return `${JSON.stringify(serverError.response.data)} ${serverError.response.status}`;
            }
        }catch (e){
            return `Неизвестная ошибка ${JSON.stringify(e)}`
        }
    }
}