import {JSXElementConstructor} from "react";
import AuthPage from "../Pages/AuthPage";
import CustomersPage from "../Pages/CustomersPage";
import NewEmailPage from "../Pages/NewEmailPage";
import LettersPage from "../Pages/LettersPage";
import UsersPage from "../Pages/UsersPage";
import CustomerDetailPage from "../Pages/CustomerDetailPage";
import NewEmailPageV2 from "../Pages/NewEmailPageV2";
import NewEmailStep1 from "../Pages/NewEmail/NewEmailStep1";
import NewEmailStep3 from "../Pages/NewEmail/NewEmailStep3";
import NewEmailStep2 from "../Pages/NewEmail/NewEmailStep2";
import NewEmailResult from "../Pages/NewEmail/Result";
import GroupsPage from "../Pages/GroupsPage";
import UnsubscribePage from "../Pages/UnsubscribePage";
import ImportCustomer from "../Pages/ImportCustomer";



export interface IRoute{
    path:string
    component:JSXElementConstructor<any|string>
    exact?:boolean
    index?:boolean
}
export enum RouteNames{
    LOGIN='/',
    DASHBOARD='/dashboard',
    CUSTOMERS='/customers',
    SETTINGS='/settings',
    NEW_LETTERS='/new_email',
    LETTERS='/letters',
    USERS='/users',
    GROUPS='/groups',
    CUSTOMER_DETAIL='/customer',
    NEW_LETTERS_STEP1='/new_email/step1',
    NEW_LETTERS_STEP2='/new_email/step2',
    NEW_LETTERS_STEP3='/new_email/step3',
    NEW_LETTERS_RESULT='/new_email/result',
    UNSUBSCRIBE='/unsubscribe',
    IMPORTS_TASKS='/imports-tasks',


}

export const publicRoutes:IRoute[]=[
    {path: RouteNames.LOGIN,exact:true,component: AuthPage,index:true},
    {path: RouteNames.UNSUBSCRIBE,component: UnsubscribePage,index:true},

]
export const privateRoutes:IRoute[]=[
    {path: RouteNames.CUSTOMERS,exact:true,component: CustomersPage,index:true},
    {path: RouteNames.LOGIN,exact:true,component: AuthPage},
    {path: RouteNames.NEW_LETTERS,exact:true,component: NewEmailPageV2},
    {path: RouteNames.CUSTOMER_DETAIL,exact:true,component: CustomerDetailPage},
    {path: RouteNames.LETTERS,exact:true,component: LettersPage},
    {path: RouteNames.USERS,exact:true,component: UsersPage},
    {path: RouteNames.NEW_LETTERS_STEP1,exact:true,component: NewEmailStep1},
    {path: RouteNames.NEW_LETTERS_STEP2,exact:true,component: NewEmailStep2},
    {path: RouteNames.NEW_LETTERS_STEP3,exact:true,component: NewEmailStep3},
    {path: RouteNames.NEW_LETTERS_RESULT,exact:true,component: NewEmailResult},
    {path: RouteNames.GROUPS,exact:true,component: GroupsPage},
    {path: RouteNames.IMPORTS_TASKS,exact:true,component: ImportCustomer},
]