import {FC, useContext} from "react";
import {Navigate, Route,Routes} from "react-router-dom";
import {privateRoutes, publicRoutes, RouteNames} from "../Routes";
import {Context} from "../index";
import {observer} from "mobx-react-lite";



const AppRouter:FC=()=>{

    const {user} = useContext(Context);
    if (localStorage.getItem('token')) {
        user.setAuth(true)
    }

    return(
        user.isAuth?
            <Routes>
                {
                    publicRoutes.map(item=>
                        <Route key={item.path} path={item.path} element={<item.component/>}/>
                    )
                }
                {
                    privateRoutes.map(item=>
                        <Route key={item.path}  path={item.path} element={<item.component/>}/>
                    )
                }
                <Route  path='*' element={<Navigate to={RouteNames.LOGIN}/>} />
            </Routes>
            :
            <Routes>
                {
                    publicRoutes.map(item=>
                        <Route key={item.path} path={item.path} element={<item.component/>}/>
                    )
                }
                <Route  path='*' element={<Navigate to={RouteNames.LOGIN}/>} />
            </Routes>

    )

}
export default observer(AppRouter);