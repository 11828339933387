import React from 'react';
import AppRouter from "./Components/AppRouter";
import {BrowserRouter} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ru} from "date-fns/locale";

function App() {
  return (
      <LocalizationProvider dateAdapter={AdapterDayjs} locale={ru}>
          <BrowserRouter>
              <AppRouter />
          </BrowserRouter>
      </LocalizationProvider>
  );
}

export default App;
