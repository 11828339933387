import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel
} from '@mui/material';
import React, {FC} from 'react';
import {ICustomer} from "../../../Models/ICustomer";
import {useNavigate} from "react-router-dom";
import {RouteNames} from "../../../Routes";

interface CustomerTablesInterface {
    customers:ICustomer[]
}
const CustomerTables:FC<CustomerTablesInterface> = (props) => {
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const  history=useNavigate()

    const isSelected = (name: number) => selected.indexOf(name) !== -1;

    const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    return (
        <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={'medium'}
            >
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                inputProps={{
                                    'aria-label': 'select all desserts',
                                }}
                            />
                        </TableCell>
                        <TableCell
                            key={'header_1'}
                            align={ 'left'}
                            padding={'normal'}
                        >
                            Почта
                        </TableCell>
                            <TableCell
                                key={'header_2'}
                                align={ 'left'}
                                padding={'normal'}
                            >
                                Имя
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.customers.map((row,index)=>{
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => history(`${RouteNames.CUSTOMER_DETAIL}?id=${row.id}`)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />

                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                    >
                                        {row.email}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                    >

                                        {row.name}
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CustomerTables;