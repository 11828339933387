import {makeAutoObservable} from "mobx";
import AuthService from "../Service/AuthService";
import Errorhandler from "../errorHendler";
import {IUser} from "../Models/IUser";
import {ISender} from "../Models/ISender";
import EmailManagerService from "../Service/EmailManagerService";

export default class UserStore{
    isAuth = false;
    user = {} as IUser;
    senderList=[]as ISender[]

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }
    setUser(user: IUser) {
        this.user = user;
    }
    constructor() {
        makeAutoObservable(this);
        this.fetchSenders().then();
    }
    async fetchSenders(){
        try {
            const res=await EmailManagerService.fetchSenderList();
            this.senderList=res.data
        }catch (e){
            alert(Errorhandler.errorMessage(e))
            throw new Error()
        }
    }

    async login(data:any){
        try{
            const res=await AuthService.login(data);
            localStorage.setItem('token',res.data.token);
            this.setAuth(true);
            this.setUser(res.data);
            return true;
        }catch (e){
            alert(Errorhandler.errorMessage(e))
            throw new Error()
        }

    }
}