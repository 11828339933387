import React, {ChangeEvent, FC} from 'react';
import {Autocomplete, TextField} from "@mui/material";
import {ICustomer} from "../../Models/ICustomer";


interface AutocompleteTagsInterface<ClassKey extends string = string>{
    id?:string
    options:any[]
    label:string
    placeholder:string
    onChange:(event:ChangeEvent<{}>, value:any[])=>void
    className?: string
    defaultValue?:any[]

}

const AutocompleteTags:FC<AutocompleteTagsInterface> = (props) => {
    return (
        <Autocomplete
            className={props.className}
            multiple
            id={props.id}
            options={props.options}
            onChange={(event, value) => {
                props.onChange(event,value);
            }}

            defaultValue={(props.defaultValue!==undefined)?[...props.options.filter(f=>props.defaultValue?.find(c=>c==f.id)>0)]:undefined}
            getOptionLabel={(option) => `${option.name} (${option.email})`}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={props.label}
                    placeholder={props.placeholder}
                    margin="dense"

                />
            )}
        />
    );
};

export default AutocompleteTags;