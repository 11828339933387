import React, {FC, useEffect, useState} from 'react';
import {Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import EmailManagerService from "../../Service/EmailManagerService";
import {IRecipients} from "../../Models/IRecipients";


interface RecipientsComponentsProps{
    onChange:(recipients:number[])=>void
    recipients:number[]
}
const RecipientsComponents:FC<RecipientsComponentsProps> = (props) => {
    const [recipients,setRecipients]=useState<IRecipients[]>([])
    const [selected, setSelected] = React.useState<readonly IRecipients[]>([]);
    const isSelected = (id: IRecipients) => selected.indexOf(id) !== -1;
    useEffect(()=>{
        EmailManagerService.fetchRecipients().then(value => {
            setRecipients(value.data)
        })

    },[])


    const handleClick = (event: React.MouseEvent<unknown>, resipients: IRecipients) => {
        const selectedIndex = selected.indexOf(resipients);
        let newSelected: readonly IRecipients[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, resipients);
        }else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        }else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        props.onChange(newSelected.map(e=>e.id))
    };

    return (
        <div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Название группы</TableCell>
                            <TableCell>Описание</TableCell>
                            <TableCell>Получателей</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recipients.map((row,index) => {
                            const isItemSelected = isSelected(row);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    sx={{ cursor: 'pointer' }}
                                    key={row.id}
                                    onClick={(event) => handleClick(event, row)}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell >{row.description}</TableCell>
                                    <TableCell >{row.customerCount}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default RecipientsComponents;