import React, {useState} from 'react';
import NewEmailPageV3 from "./NewEmailPageV3";
import {NewEmailContainer} from "./ui/NewEmailContainer";
import {Box, Button, Tabs, Typography} from "@mui/material";
import NewEmailForm from "./ui/NewEmailForm";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Editor} from "react-draft-wysiwyg";
import EmailManagerService from "../../Service/EmailManagerService";
import draftToHtml from "draftjs-to-html";
import {convertToRaw, EditorState} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Tab from "@mui/material/Tab";
import CodeEditor from "@uiw/react-textarea-code-editor";

type Inputs = {
    messageBody: string,
};
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const NewEmailStep1 = () => {
    const {  handleSubmit,control,setValue } = useForm<Inputs>();
    let navigate = useNavigate();
    const onSubmit: SubmitHandler<Inputs> = data => {
        alert(data.messageBody)
    };

    const uploadCallback = (file:File,) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const res = await EmailManagerService.uploadImage(file)
                /*setValue("thumbnail", res.data);
                resolve({ data: { link: process.env.REACT_APP_API + res.data } });*/
            };
            reader.readAsDataURL(file);
        });
    };

    const config = {
        image: { uploadCallback: uploadCallback },
    };
    const handleEditorChange = (state:EditorState) => {
        setEditorState(state);
        setValue('messageBody',draftToHtml(convertToRaw(state.getCurrentContent())))
    };
    const [page, setPage] = React.useState(2);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setPage(newValue);
    };
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    return (
        <NewEmailPageV3>
            <NewEmailContainer>
                <Typography component={'h2'} variant={'h5'}>Шаг 1 - Сообщение</Typography>
                <NewEmailForm onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Tabs value={page} onChange={handleChange} aria-label="disabled tabs example">
                            <Tab label="Редактор" />
                            <Tab label="Свой HTML" />
                            <Tab label="Рассылка для Гринвея" />
                            <Tab label="Рассылка статьи Гринвея" />
                            <Tab label="Рассылка Напитки" />
                        </Tabs>
                        <TabPanel value={page} index={0}>
                            <Controller
                                render={({field:{onChange,onBlur,value}})=>{
                                  return <Editor
                                      toolbarClassName="toolbarClassName"
                                      wrapperClassName="wrapperClassName"
                                      editorClassName="editorClassName"
                                      editorState={editorState}
                                      onEditorStateChange={handleEditorChange}
                                  />
                                }}
                                name={'messageBody'}
                                />
                        </TabPanel>

                        <TabPanel value={page} index={1}>
                            <Controller
                                render={({ field:{onChange,onBlur,value}, fieldState, formState, })=>{
                                    return <CodeEditor
                                        value={value}
                                        language="html"
                                        placeholder="Укажите код письма"
                                        onChange={(evn) => onChange(evn.target.value)}
                                        padding={15}
                                        rows={100}
                                        style={{
                                            fontSize: 12,
                                            backgroundColor: "#f5f5f5",
                                            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                            color: "#539058"
                                        }}
                                    />
                                }}
                                name={'messageBody'}
                                control={control}/>
                        </TabPanel>

                        <TabPanel value={page} index={2}>
                            <Controller
                                render={({field:{onChange,onBlur,value}})=>{
                                    return <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        editorState={editorState}
                                        onEditorStateChange={handleEditorChange}
                                    />
                                }}
                                name={'messageBody'}
                            />
                        </TabPanel>
                        <TabPanel value={page} index={3}>
                            <Controller
                                render={({field:{onChange,onBlur,value}})=>{
                                    return <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        editorState={editorState}
                                        onEditorStateChange={handleEditorChange}
                                    />
                                }}
                                name={'messageBody'}
                            />
                        </TabPanel>
                        <TabPanel value={page} index={4}>
                            <Controller
                                render={({field:{onChange,onBlur,value}})=>{
                                    return <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        editorState={editorState}
                                        onEditorStateChange={handleEditorChange}
                                    />
                                }}
                                name={'messageBody'}
                            />
                        </TabPanel>
                    </div>
                    <Button type={'submit'}>Далее</Button>
                </NewEmailForm>
            </NewEmailContainer>
        </NewEmailPageV3>
    );
};

export default NewEmailStep1;