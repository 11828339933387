import React, {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {IImportTasks} from "../../Models/IImportTasks";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import AutocompleteTags from "../Inputs/AutocompleteTags";
import {IGroup} from "../../Models/IGroup";
import CustomersService from "../../Service/CustomersService";
import Dropzone from "react-dropzone";
import {Box, Button, Paper, Typography} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { useSnackbar } from 'notistack';
import Errorhandler from "../../errorHendler";

interface NewImportTaskDialogInterface {
    open:boolean
    handleClose:()=>void
    handleCreate:(customer:IImportTasks)=>void

}
interface NewCustomerState{
    groups:IGroup[]
    selectGroups:IGroup[]
    file?:File
}
const NewImportTaskDialog:FC<NewImportTaskDialogInterface> = (props) => {
    const [state,setState]=useState<NewCustomerState>({groups:[],selectGroups:[],})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(()=>{
        CustomersService.fetchGroups().then(v=>{
            setState(prevState => ({...prevState,groups:v.data}))
        })
    },[])

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files&&e.target.files[0]!=null) {
            const f=e.target.files[0]
            setState(prevState => ({...prevState,file:f}));
        }
    };
    const create = async () => {
      try{
          if(state.file===undefined){
              enqueueSnackbar('Выберете файл для импорта!',{variant:'error'})
              return
          }
          const res=await CustomersService.createImportTasks(state.file,state.selectGroups.map(e=>e.id))
          props.handleCreate(res.data)
      }catch (e){
          alert(Errorhandler.errorMessage(e))
      }
    }
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Новый Импорт</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Добавление новой задачи для импорта почт из файла excel. Для импорта добавте все почты в первый столбец.
                </DialogContentText>
                <>
                    <div>
                        <input type="file" onChange={handleFileChange} />
                        <div>{state.file && `${state.file.name} - ${state.file.type}`}</div>
                    </div>
                </>
                <AutocompleteTags
                    label="Группы"
                    onChange={(c,value)=>{
                        setState(prevState => ({...prevState,selectGroups:value}))
                    }}
                    options={state.groups}
                    placeholder="Укажите группы"
                />
            </DialogContent>
            <DialogActions>
                <Button  onClick={create}>
                    Сохранить файл и начать импорт
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewImportTaskDialog;