import React, {FC, useEffect, useState} from 'react';
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {
    Box, Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel, ListItem,
    MenuItem, Paper,
    Select, Stack,
    TextField,
    Typography
} from "@mui/material";
import {ISenderProfile} from "../../Models/ISenderProfile";
import EmailManagerService from "../../Service/EmailManagerService";
import List from "@mui/material/List";
import EmailSendDateSettingsDialog, {SenderEnum} from "../Dialogs/EmailSendDateSettingsDialog";
import {senderOptions} from "../../Models/dto/send-options.dto";
import Dropzone from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import {format} from "date-fns";

type InputsAbout = {
    subject: string,
    name: string,
    sendersId:number
    file: File[]
};
interface AboutMessageProps{
    onSubmit: SubmitHandler<InputsAbout>
    data:InputsAbout
}

const AboutMessage:FC<AboutMessageProps> = (props) => {
    const [dateSelectorOpen,setDateSelectorOpen]=useState(false)
    const [sendOptions,setSendOptions]=useState<senderOptions[]>([{type:'now',notify:'none'}])
    const [profiles,setProfiles]=useState<ISenderProfile[]>([])

        const { register, handleSubmit,
        watch, formState: { errors },setValue,
            control,getValues } = useForm<InputsAbout>();

    useEffect(()=>{
        EmailManagerService.fetchSendProfile().then(value => {
            setProfiles(value.data)
            if(value.data.length>0){
                setValue('sendersId',value.data[0].id)
            }
        })

        setValue('subject',props.data.subject)
        setValue('name',props.data.name)
        setValue('file',props.data.file)
    },[])

    useEffect(()=>{
        return props.onSubmit({
            subject:getValues('subject'),
            name:getValues('name'),
            sendersId:getValues("sendersId"),
            file:getValues("file")
        }as InputsAbout);
    },[watch])

    const deleteOption = (option:senderOptions) => {
        setSendOptions(prevState => prevState.filter(e=>e!==option))
    }

    return (
        <Box sx={{ m:2 }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography sx={{m:1}} variant={'h6'}>Данные об отправке</Typography>
                    <form onSubmit={handleSubmit(props.onSubmit)}>
                        <TextField
                            label="Название рассылки"
                            variant="outlined"
                            fullWidth={true}
                            sx={{ m: 1 }}
                            error={errors.name?.message!=undefined}
                            helperText={errors.name?.message??undefined}
                            {...register('name',{required:'Заполните Название'})}
                        />
                        <TextField
                            label="Тема сообщения"
                            variant="outlined"
                            fullWidth={true}
                            sx={{ m: 1,}}
                            {...register('subject',{required:'Заполните тему'})}
                            error={errors.subject?.message!=undefined}
                            helperText={errors.subject?.message??undefined}
                        />
                        <FormControl sx={{ m: 1,  }} fullWidth>
                            <InputLabel id="demo-select-small">Укажите отправителя</InputLabel>
                            <Controller
                                render={({field:{onChange,onBlur,value,}})=><Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    label="Укажите отправителя"
                                    fullWidth={true}
                                    onChange={onChange}
                                    value={value}
                                    error={errors.sendersId?.message!=undefined}
                                >
                                    {
                                        profiles.map(profile=><MenuItem value={profile.id}>{profile.name}({profile.from})</MenuItem>)
                                    }
                                </Select>
                                }
                                name={'sendersId'}
                                control={control}
                                rules={{ required: 'Укажите отправителя' }}
                            />
                            {
                                (errors.sendersId?.message!=undefined)&& <FormHelperText>{errors.sendersId?.message}</FormHelperText>
                            }
                        </FormControl>
                        <Controller
                            render={({field:{onChange,onBlur,value}}) => (
                                <>
                                    <Dropzone
                                        onDrop={onChange}
                                        maxFiles={5}
                                        maxSize={50000}
                                    >
                                        {
                                            ({getRootProps,getInputProps})=>(
                                                <Paper {...getRootProps()} variant={'outlined'} style={{width: '100%',}} sx={{m:1}} >
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        minHeight="40vh"
                                                        sx={{
                                                            p:4
                                                        }}>
                                                        <Typography  style={{textAlign:'center'}}>Загрузите с компьютера или перетащите файлы для отправки</Typography>
                                                    </Box>
                                                    <input {...getInputProps()} name={'file'} hidden={true} onBlur={onBlur}/>
                                                </Paper>
                                            )
                                        }
                                    </Dropzone>
                                    {
                                        value.map((element)=><p>{element.name}</p>)
                                    }
                                    {
                                        value.length
                                    }
                                </>
                            )}
                            name={'file'}
                            control={control}
                            defaultValue={[]}
                        />
                        <button type={'submit'}/>
                    </form>
                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{m:1}} variant={'h6'}>Планирование</Typography>
                    <Button sx={{m:1}} onClick={()=>setDateSelectorOpen(true)} variant="outlined">Заплонировать отправку</Button>
                    <List sx={{m:1}} style={{maxHeight: '60%', overflow: 'auto'}}>
                        {
                            sendOptions.map(e=>{
                                let primary='';
                                let secondary='';

                                switch (e.type){
                                    case SenderEnum.now:
                                        primary='Отправка сразу после создания';
                                        break;
                                    case SenderEnum.oneSend:
                                        primary='Точная дата дата'
                                        secondary= `Отправить ${format(e.dateSend!,'dd.MM.yy HH:mm')}`
                                        break;
                                    case SenderEnum.regular:
                                        switch (e.regularType) {
                                            case 'everyMonth':
                                                primary= `ежемесячно`
                                                secondary=`каждое ${e.dayNumber} число в ${format(e.timeSend!,'HH:mm')}`
                                                break;
                                            case 'everyday':
                                                primary='Ежедневно';
                                                secondary=`в ${format(e.timeSend!,'HH:mm')}`

                                                break
                                            default:
                                                primary=`${e.regularType}`;
                                                break
                                        }
                                }

                                return (
                                    <ListItem
                                        secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={()=>deleteOption(e)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                    >
                                        <ListItemText
                                            primary={primary}
                                            secondary={secondary}
                                        />
                                    </ListItem>
                                )
                            })
                        }
                    </List>

                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{m:1}} variant={'h6'}>Уведомления</Typography>

                </Grid>
            </Grid>
            <EmailSendDateSettingsDialog handleClose={() => setDateSelectorOpen(false)}
                                         handleCreate={(options) => {

                                             setSendOptions(options)
                                             setDateSelectorOpen(false);
                                         }
                                         }
                                         open={dateSelectorOpen}
                                         options={sendOptions}/>
        </Box>
    );
};

export default AboutMessage;