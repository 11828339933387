import React, {useContext} from 'react';
import {Avatar, Box, Button, CircularProgress, Container, CssBaseline, TextField, Typography} from "@mui/material";
import {green} from "@mui/material/colors";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {SubmitHandler, useForm} from "react-hook-form";
import {RouteNames} from "../Routes";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CustomerInfoDialog from "../Components/Dialogs/CustomerInfoDialog";
import Errorhandler from "../errorHendler";


type LoginInputs = {
    email: string,
    password: string,
};

const AuthPage = () => {


    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm<LoginInputs>();


    const history=useNavigate();
    const {user} =useContext(Context)
    const onSubmit: SubmitHandler<LoginInputs> = async (data) => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);

        }
        try{
            await user.login(data);
            history(RouteNames.CUSTOMERS)

        }catch (e){
            alert(Errorhandler.errorMessage(e))
        }finally {
            setSuccess(true);
            setLoading(false);
        }
    };
    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };


    return (
        <Container component="main" maxWidth="xs">

            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Служба рассылки
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Логин"
                        {...register("email")}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Пароль"
                        type="password"
                        autoComplete="current-password"
                        {...register("password")}
                    />


                    <Box sx={{ mt: 1, mb:1, position: 'relative' }}>
                        <Button
                            variant="contained"
                            sx={buttonSx}
                            disabled={loading}
                            type="submit"
                            fullWidth

                        >
                            Вход
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default observer(AuthPage);