import React, {FC, useEffect, useState} from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Button, FormControl, Grid, InputLabel, ListItem, MenuItem, Select, Stack, Typography} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {DateTimePicker, TimePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import dayjs, {Dayjs} from "dayjs";
import {senderOptions} from "../../Models/dto/send-options.dto";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns'

interface NewEmailSendDateSettingsAttrs {
    open:boolean
    handleClose:()=>void
    handleCreate:(options:senderOptions[])=>void
    options:senderOptions[]
}
export enum SenderEnum {
    regular='regular',
    oneSend='oneSend',
    now='now',
}
enum RegularTypeEnum {
    everyday='everyday',
    everyWeek='everyWeek',
    everyMonth='everyMonth',
    setDaya='setDaya',
}

interface IFormInput {
    firstName: String;
    settingType: SenderEnum;
    dateSend?:Date
    regularType?:RegularTypeEnum


}



const EmailSendDateSettingsDialog:FC<NewEmailSendDateSettingsAttrs> = (props) => {

    const { register, handleSubmit,watch,getValues } = useForm<IFormInput>();
    const onSubmit: SubmitHandler<IFormInput> = data => console.log(data);
    const [value, setValue] = React.useState<Dayjs | null>(dayjs(Date.now()));
    const [time, setTime] = React.useState<Dayjs | null>(dayjs(Date.now()));
    const [options,setOptions]=useState<senderOptions[]>([])
    const [dayNumber,setDayNumber]=useState<number>()
    const [notify,setNotify]=useState<string>('none')

    const addSend = () => {
        setOptions(prevState => [...prevState, {
            type: watch('settingType').toString(),
            dateSend: value?.toDate(),
            timeSend: time?.toDate(),
            dayNumber: dayNumber,
            regularType: watch('regularType')?.toString(),
            notify:notify
        }])
        console.log(JSON.stringify(options))
    }

    useEffect(()=>{
        setOptions(props.options);
    },[])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogTitle>Настройки времени отправки</DialogTitle>
            <DialogContent>
                <Stack
                    justifyContent="space-evenly"
                    direction="row"
                    spacing={2}
                    style={{width:'100%'}}
                >
                    <Box
                        style = {{width: '100%'}}
                        sx={{p:2}}
                    >
                        <form>
                            <FormControl fullWidth={true} style = {{width: '100%',minWidth: '100%'}} >
                                <InputLabel id="demo-simple-select-label">Когда отправить?</InputLabel>
                                <Select
                                    {...register("settingType",{ required: true})}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Тип отправки"
                                    fullWidth={true}
                                    style = {{width: '100%',minWidth: '100%'}}
                                >
                                    <MenuItem value={SenderEnum.now}>Отправить сейчас</MenuItem>
                                    <MenuItem value={SenderEnum.oneSend}>Выбрать дату и время</MenuItem>
                                    <MenuItem value={SenderEnum.regular}>Регулярная отправка</MenuItem>
                                </Select>
                            </FormControl>
                            {
                                (watch('settingType')==SenderEnum.now)&&<>
                                    <Button variant="contained" onClick={addSend}>Добавить</Button>
                                </>
                            }
                            {
                                (watch('settingType')==SenderEnum.oneSend)&&<>

                                    <Box sx={{mt:1,mb:1}}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField {...props} fullWidth={true}/>}
                                            label="Дата и время отправки"
                                            value={value}
                                            onChange={(newValue) => {
                                                setValue(newValue);
                                            }}
                                            inputFormat={'DD.MM.YYYY HH:mm'}
                                        />

                                    </Box>
                                    <Button variant="contained" onClick={addSend}>Добавить</Button>

                                </>
                            }
                            {
                                (watch('settingType')==SenderEnum.regular)&&<>
                                    <FormControl fullWidth={true} style = {{width: '100%',minWidth: '100%'}} sx={{mt:1,mb:1}}>
                                        <InputLabel id="date-send-select-label">Когда отправить?</InputLabel>
                                        <Select
                                            {...register("regularType",{ required: false})}
                                            labelId="date-send-select-label"
                                            id="demo-simple-select"
                                            label="Тип отправки"
                                            fullWidth={true}
                                            style = {{width: '100%',minWidth: '100%'}}
                                        >
                                            <MenuItem value={RegularTypeEnum.everyday}>Каждый день</MenuItem>
                                            <MenuItem value={RegularTypeEnum.everyMonth}>Каждый месяц</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {
                                        (watch('regularType')==RegularTypeEnum.everyday)&&<>
                                            <Box sx={{mt:1,mb:1}}>
                                                <TimePicker
                                                    label="Укажите время отправки"
                                                    value={time}
                                                    onChange={setTime}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />

                                            </Box>
                                        </>
                                    }
                                    {
                                        (watch('regularType')==RegularTypeEnum.everyMonth)&&<>
                                            <FormControl fullWidth={true} style = {{width: '100%',minWidth: '100%'}} sx={{mt:1,mb:1}}>
                                                <InputLabel id="date-select-label">Укажите день отправки</InputLabel>
                                                <Select
                                                    labelId="date-select-label"
                                                    label="Тип отправки"
                                                    fullWidth={true}
                                                    value={dayNumber}
                                                    onChange={(event)=>setDayNumber(event.target.value as number)}
                                                    style = {{width: '100%',minWidth: '100%'}}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={6}>6</MenuItem>
                                                    <MenuItem value={7}>7</MenuItem>
                                                    <MenuItem value={8}>8</MenuItem>
                                                    <MenuItem value={9}>9</MenuItem>
                                                    <MenuItem value={10}>10</MenuItem>

                                                    <MenuItem value={11}>11</MenuItem>
                                                    <MenuItem value={12}>12</MenuItem>
                                                    <MenuItem value={13}>13</MenuItem>
                                                    <MenuItem value={14}>14</MenuItem>
                                                    <MenuItem value={15}>15</MenuItem>
                                                    <MenuItem value={16}>16</MenuItem>
                                                    <MenuItem value={17}>17</MenuItem>
                                                    <MenuItem value={18}>18</MenuItem>
                                                    <MenuItem value={19}>19</MenuItem>
                                                    <MenuItem value={20}>20</MenuItem>

                                                    <MenuItem value={21}>21</MenuItem>
                                                    <MenuItem value={22}>22</MenuItem>
                                                    <MenuItem value={23}>23</MenuItem>
                                                    <MenuItem value={24}>24</MenuItem>
                                                    <MenuItem value={25}>25</MenuItem>
                                                    <MenuItem value={26}>26</MenuItem>
                                                    <MenuItem value={27}>27</MenuItem>
                                                    <MenuItem value={28}>28</MenuItem>
                                                    <MenuItem value={29}>29</MenuItem>
                                                    <MenuItem value={30}>30</MenuItem>
                                                    <MenuItem value={31}>31</MenuItem>


                                                </Select>
                                            </FormControl>
                                            <Box sx={{mt:1,mb:1}}>
                                                <TimePicker
                                                    label="Укажите время отправки"
                                                    value={time}
                                                    onChange={setTime}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Box>
                                        </>
                                    }
                                    <FormControl fullWidth={true} style = {{width: '100%',minWidth: '100%'}} sx={{mt:1,mb:1}}>
                                        <InputLabel id="date-select-label">Уведомление перед отправкой</InputLabel>
                                        <Select
                                            labelId="date-select-label"
                                            label="Уведомление перед отправкой"
                                            fullWidth={true}
                                            value={notify}
                                            onChange={(event)=>setNotify(event.target.value as string)}
                                            style = {{width: '100%',minWidth: '100%'}}
                                        >
                                            <MenuItem value={'none'}>Нет</MenuItem>
                                            <MenuItem value={'hour'}>За час до отправки</MenuItem>
                                            <MenuItem value={'3_hour'}>За 3 часа до отправки</MenuItem>
                                            <MenuItem value={'day'}>За сутки до отправки</MenuItem>
                                            <MenuItem value={'3_day'}>За 3 суток до отправки</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button variant="contained" onClick={addSend}>Добавить</Button>
                                </>
                            }

                        </form>
                    </Box>
                    <Box style = {{width: '100%'}}>
                        <Typography>Список отправок</Typography>
                        <List>
                            {
                                options.map(item=>{
                                    var primary='';
                                    var secondary='';
                                    switch (item.type){
                                        case 'now':
                                            primary='Отправить сейчас';
                                            break;
                                        case 'oneSend':
                                            primary='Отправка по дате';
                                            secondary=`${format(item.dateSend!,'dd.MM.yy HH:mm')}`;
                                            break;
                                        case 'regular':
                                            switch (item.regularType) {
                                                case 'everyMonth':
                                                    primary='Ежемесячно'
                                                    secondary=`Каждое ${item.dayNumber} число в ${format(item.timeSend!,'HH:mm')}`
                                                    break;
                                            }
                                            break;
                                    }
                                    return <ListItem
                                        secondaryAction={
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={()=>setOptions(prevState => options.filter(element=>item!==element))}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }>
                                        <ListItemText
                                            primary={primary}
                                            secondary={secondary}
                                        />
                                    </ListItem>
                                })
                            }
                        </List>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button  onClick={()=>props.handleCreate(options)}>
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailSendDateSettingsDialog;