import React, {useEffect, useState} from 'react';
import MainComponent from "../Components/MainComponent";
import TitleRow from "../Components/Title";
import {Box, Button, ListItem, Stack} from "@mui/material";
import List from "@mui/material/List";
import {ICustomer} from "../Models/ICustomer";
import {IGroup} from "../Models/IGroup";
import ListItemText from "@mui/material/ListItemText";
import CustomersService from "../Service/CustomersService";
import NewGroupsDialog from "../Components/Dialogs/NewGroupsDialog";

const GroupsPage = () => {

    const [groups,setGroups]=useState<IGroup[]>([]);
    const [show,setShow]=useState(false)
    useEffect(()=>{
        CustomersService.fetchGroups().then(v=>{
            setGroups(v.data)
        })
    },[])

    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Группы пользователей`} actions={
                    <Stack direction={"row"}>
                        <Button variant="contained" onClick={()=>{setShow(true)}}>Новая группа</Button>
                    </Stack>
                }/>
            </React.Fragment>
            <Box sx={{ width: '100%', typography: 'body1' ,height: '75vp'}}>
                <List sx={{
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: '74vp',
                }}>
                    {
                        groups.map(g=>
                            <ListItem key={g.id}>
                                <ListItemText primary={g.name} secondary={g.description} />
                            </ListItem>
                        )
                    }
                </List>
            </Box>
            <NewGroupsDialog open={show} handleClose={function(): void {
                setShow(false)
            } } handleCreate={function(customer: IGroup): void {
                setGroups(prevState => ([customer,...prevState]))
                setShow(false)
            } }/>

        </MainComponent>
    );
};

export default GroupsPage;