import React, {createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState} from "react";



const NewEmailContext =createContext({

})

export const NewEmailProvider:FC<PropsWithChildren<unknown>> =({children})=>{
    const [data,setData]=useState({})

    const setValue=(values:any)=>{
        setData(prevState => ({
            ...prevState,
            ...values
        }))
    }
    return (
        <NewEmailContext.Provider value={{data,setValue}}>
            {children}
        </NewEmailContext.Provider>
    )
}
export const useNewEmailData=()=>useContext(NewEmailContext)