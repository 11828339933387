import React from 'react';
import MainComponent from "../Components/MainComponent";
import TitleRow from "../Components/Title";
import {Box, Button, Grid, Paper, Stack, Step, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import MessageInput from "../Components/StepsComponent/MessageInput";
import AboutMessage from "../Components/StepsComponent/AboutMessage";
import RecipientsComponents from "../Components/StepsComponent/RecipientsComponents";
import TotalData from "../Components/StepsComponent/TotalData";
import {NewEmailPageState} from "../Models/dto/NewEmailPageState";

const steps = ['Сообщение', 'Тема письма ', 'Получатели','Проверка'];

const NewEmailPageV2 = () => {
    const [state, setState] = React.useState<NewEmailPageState>({
        messageBody: '',
        activeStep: 0,
        sendersId: 1,
        name: '',
        subject: '',
        recipients:[],
        file: [],
        isArticle:false,
        articleText:undefined
    })


    const handleReset = () => {
        setState((prevActiveStep) => ({...prevActiveStep,activeStep: 0}));
    };
    const handleBack = () => {
        setState((prevActiveStep) => ({...prevActiveStep,activeStep:prevActiveStep.activeStep - 1}));
    };

    const handleNext = () => {
        if(state.messageBody===''){
            alert('Заполните текст письма!')
            return;
        }
        setState((prevActiveStep) => ({...prevActiveStep,activeStep:prevActiveStep.activeStep + 1}));
    };


    const activeComponent = () => {
      switch (state.activeStep){
          case 0:
              return (
                  <MessageInput
                      message={state.messageBody}
                      onChange={(message,isArticle,articleText)=>{
                          setState(prevState => ({...prevState,messageBody:message,isArticle:isArticle,articleText:articleText}))
                      }}
                  />
              );
          case 1:
              return (
                  <AboutMessage onSubmit={(data)=>{
                      setState(prevState => ({...prevState,...data}))

                  }} data={state}/>
              );
          case 2:
              return (
                  <RecipientsComponents
                      onChange={
                      (recipients)=>setState(prevState => ({...prevState,recipients:recipients}))
                  }
                   recipients={state.recipients}/>
              )
          case 3:
              return (
                  <TotalData
                      {...state}
                  />
              )
      }
    }



    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Новая рассылка`} actions={
                    <Stack direction={"row"}>
                    </Stack>
                }/>
            </React.Fragment>
            <Box sx={{ width: '100%', height: '100%' ,minHeight:'100%'}} bgcolor="background.paper">
                <Stepper activeStep={state.activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                {state.activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            Рассылка отправленна!
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Сбросить все поля</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {
                            activeComponent()
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={state.activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            <Button onClick={handleNext}>
                                {state.activeStep === steps.length - 1 ? 'Отправить' : 'Продолжить'}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </MainComponent>
    );

};

export default NewEmailPageV2;