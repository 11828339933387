import React, {useEffect, useState} from 'react';
import TitleRow from "../Components/Title";
import {
    Box,
    Button,
    Grid,
    ListItem,
    Paper,
    Stack,
    Table, TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";

import MainComponent from "../Components/MainComponent";
import Errorhandler from "../errorHendler";
import EmailManagerService from "../Service/EmailManagerService";
import {ILetters} from "../Models/dto/ILetters";
import {format} from "date-fns";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useNavigate} from "react-router-dom";
import {RouteNames} from "../Routes";
import LettersRow from "../Components/ui/tables/LettersRow";
import {ISenderPackShort} from "../Models/ISenderPackShort";

const LettersPage = () => {
    const navigate=useNavigate();


    const [letters,setLetters]=useState<ISenderPackShort[]>([])
    let page=0;
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const fetchLetters =async () => {
      try{
          const list=await EmailManagerService.fetchSenderPack(page);
          if (list.data.length>0){
              setLetters(prevState => [...prevState,...list.data]);
              page++;
          }
      }catch (e){
          alert(Errorhandler.errorMessage(e))
      }
    }
    useEffect(()=>{
        fetchLetters().then();
    },[])

    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Рассылки`} actions={
                    <Stack direction={"row"}>
                        <Button variant="contained" onClick={()=>navigate(RouteNames.NEW_LETTERS)}>Новый рассылка</Button>
                    </Stack>
                }/>
            </React.Fragment>
            <Paper>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TableContainer sx={{ maxHeight: '100vp' }}>
                        <Table stickyHeader aria-label="collapsible sticky  table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Название</TableCell>
                                    <TableCell align="right">Тема письма</TableCell>
                                    <TableCell align="right">Получателей</TableCell>
                                    <TableCell align="right">Отправленно</TableCell>
                                    <TableCell align="right">С ошибкой</TableCell>
                                    <TableCell align="right">Успешно</TableCell>
                                    <TableCell align="right">Просмотров</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    letters.map(e=><LettersRow {...e}/>)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>

        </MainComponent>
    );
};

export default LettersPage;