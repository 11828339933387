import {Box, Button, Tabs} from '@mui/material';
import React, {FC, useState} from 'react';
import Tab from "@mui/material/Tab";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CodeEditor from '@uiw/react-textarea-code-editor';
import draftToHtml from "draftjs-to-html";
import {convertToRaw, EditorState} from "draft-js";
import $api from "../../http";
import {GwcoProductComponent, GwcoProductInterface} from "./GwcoProductComponent";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface MessageInputProps{
    onChange:(message:string,isArticle:boolean,textArticle?:string)=>void
    message:string,
}

const MessageInput:FC<MessageInputProps> = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [htmlValue,setHtmlValue]=useState<string>('')


    const handleEditorChange = async (state:EditorState,isArticle:boolean) => {
        setEditorState(state);
        if(value===2){
            const article_template=await $api.get<string>('/email-manager/returnTemplate/article_template');
            const content=draftToHtml(convertToRaw(editorState.getCurrentContent()));
            setHtmlValue(article_template.data.replaceAll('{{message}}',content))
            props.onChange(htmlValue,isArticle,(isArticle)?content:undefined)
        }else {
            setHtmlValue(draftToHtml(convertToRaw(editorState.getCurrentContent())))

            props.onChange(htmlValue,isArticle,(isArticle)?htmlValue:undefined)
        }
    };


    const gwcoProductWorker = async (storeData:string,items:GwcoProductInterface[]) => {
        const article_template=await $api.get<string>('/email-manager/returnTemplate/gwco_product_template');
        var productList=''
        items.map(item=>{
            productList+=` <tr>
                              <td width="80%" class="purchase_item"><span class="f-fallback">${item.name}</span>
                              </td>
                              <td class="align-right" width="20%" class="purchase_item" align="right"><span
                                class="f-fallback">${item.cost} руб.</span></td>
                            </tr>`
        })
        const text= article_template.data.replaceAll('{{store_data}}', storeData).replaceAll('{{products_list}}',productList);
        setHtmlValue(text)
        props.onChange(text,false)
    }

    return (
        <div>
            <Tabs value={value} onChange={handleChange} aria-label="disabled tabs example">
                <Tab label="Редактор" />
                <Tab label="Свой HTML" />
                <Tab label="Шаблон для Статей" />
                <Tab label="Шаблон для Продуктов" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={editorState}
                    onEditorStateChange={(t)=>handleEditorChange(t,false)}

                />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <CodeEditor
                    value={props.message}
                    language="html"
                    placeholder="Укажите код письма"
                    onChange={(evn) => props.onChange(evn.target.value,false)}
                    padding={15}
                    rows={100}
                    style={{
                        fontSize: 12,
                        backgroundColor: "#f5f5f5",
                        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                        color: "#539058"
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Button onClick={()=>navigator.clipboard.writeText(htmlValue)}>Сохранить в буфер обмена</Button>
                <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={editorState}
                    onEditorStateChange={(t)=>handleEditorChange(t,true)}
                />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Button onClick={()=>navigator.clipboard.writeText(htmlValue)}>Сохранить в буфер обмена</Button>
                <GwcoProductComponent
                    onChange={gwcoProductWorker}
                />
            </TabPanel>

        </div>
    );
};

export default MessageInput;