import {Box, Collapse, TableCell, TableRow, Typography} from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from "@mui/material/IconButton";
import {ISenderPackShort} from "../../../Models/ISenderPackShort";


const LettersRow = (row:ISenderPackShort) => {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment key={`fragment_${row.id}`}>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={`row_${row.id}`}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.subject}</TableCell>
                <TableCell align="right">{row.recipientsCount}</TableCell>
                <TableCell align="right">{row.resultCount}</TableCell>
                <TableCell align="right">{row.errorCount}</TableCell>
                <TableCell align="right">{row.sussesCount}</TableCell>
                <TableCell align="right">{row.viewsCount}</TableCell>
            </TableRow>
            <TableRow key={`row_collapse_${row.id}`}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                О рассылке:

                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default LettersRow;