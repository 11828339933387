import React, {FC, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {ICustomer} from "../../Models/ICustomer";
import {SubmitHandler, useForm} from "react-hook-form";
import CustomersService from "../../Service/CustomersService";
import Errorhandler from "../../errorHendler";
import AutocompleteTags from "../Inputs/AutocompleteTags";
import {IGroup} from "../../Models/IGroup";
import {Link} from "react-router-dom";
import {RouteNames} from "../../Routes";

interface NewCustomerInterface {
    open:boolean
    handleClose:()=>void
    handleCreate:(customer:ICustomer)=>void

}
type Inputs = {
    email: string,
    lang: string,
    name: string,
    currency: string,

};
interface NewCustomerState{
    groups:IGroup[]
    selectGroups:IGroup[]
}
const NewCustomer:FC<NewCustomerInterface> = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, reset} = useForm<Inputs>();
    const [state,setState]=useState<NewCustomerState>({groups:[],selectGroups:[]})
    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        try{
            const customer=await CustomersService.createCustomers({...data,groupsList:state.selectGroups.map(e=>e.id)})
            props.handleCreate(customer.data)
            reset();
            setState(prevState => ({...prevState,selectGroups:[]}))
        }catch (e){
            alert(JSON.stringify(e))
            alert(Errorhandler.errorMessage(e))
        }
    };

    useEffect(()=>{
        CustomersService.fetchGroups().then(v=>{
            setState(prevState => ({...prevState,groups:v.data}))
        })
    },[])


    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Новый клиент</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Добавление нового клиента в базу. Все поля обязательные к заполнению.
                </DialogContentText>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        autoFocus
                        margin="dense"
                        {...register("email",{ required: true})}
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        {...register("name",{ required: true})}
                        label="Имя"
                        type="email"
                        fullWidth
                        variant="outlined"
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="currency-select-label">Валюта пользователя</InputLabel>

                        <Select
                            label="Валюта пользователя"
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                            labelId="currency-select-label"
                            {...register("currency",{ required: true})}
                        >
                            <MenuItem value="RUB">Рубли</MenuItem>
                            <MenuItem value="EUR">Евро</MenuItem>
                            <MenuItem value="USD">Доллар</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <InputLabel id="lang-select-label">Язык пользователя</InputLabel>
                        <Select
                            margin="dense"
                            label="Язык пользователя"
                            variant="outlined"
                            labelId="lang-select-label"
                            fullWidth={true}
                            {...register("lang",{ required: true})}
                        >
                            <MenuItem value="ru">Русский</MenuItem>
                            <MenuItem value="en">Английский</MenuItem>
                        </Select>
                    </FormControl>
                    <AutocompleteTags
                        label="Группы"
                        onChange={(c,value)=>{
                            setState(prevState => ({...prevState,selectGroups:value}))
                        }}
                        options={state.groups}
                        placeholder="Получатели"
                    />

                </form>
                <p>Для массового добавления пользователей используйте <Link  to={RouteNames.IMPORTS_TASKS}>массовое добавление почт</Link></p>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Отмена</Button>
                <Button onClick={handleSubmit(onSubmit)}>Добавить</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewCustomer;