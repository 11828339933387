import React, {FC, useEffect, useState} from 'react';
import {Box, TextField} from "@mui/material";
export interface GwcoProductInterface {
    name:string
    cost:string
    index:number
}
export interface GwcoProductComponentInterface {
    onChange:(storeData:string,items:GwcoProductInterface[])=>void
}

export const GwcoProductComponent:FC<GwcoProductComponentInterface> = ({onChange}) => {
    const [items,setItems]=useState<GwcoProductInterface[]>([
        {
            name:'Eurovo Startop',cost:'320',index:1
        },
        {
            name:'Eurovo 26',cost:'295',index:2
        },
        {
            name:'Eurovo 35',cost:'276',index:3
        },
        {
            name:'Eurovo Laktolait',cost:'287',index:4
        },
        {
            name:'Eurovo Lactech',cost:'257',index:5
        },
        {
            name:'Eurovo Extra',cost:'248',index:6
        },
        {
            name:'Eurovo Lactoplus',cost:'235',index:7
        },
        {
            name:'Eurovo Lactovo',cost:'199',index:8
        },

    ])
    const [message,setMessage]=useState<string>('')

    useEffect(()=>{
       onChange(message,items)
    },[])

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off">
            <TextField
                label="Склады и остатки"
                multiline
                rows={4}
                fullWidth={true}
                value={message}
                onChange={e=>{
                    setMessage(e.target.value)
                    onChange(message,items)
                }}

            />
            {
                items.map((e,index)=><div>
                    <TextField
                        label="Название"
                        value={e.name}
                        onChange={event=>{
                            setItems(prevState => ([...prevState.map(f => (f.index !== e.index) ? f : {
                                name: event.target.value,
                                cost: f.cost,
                                index:f.index
                            })]))
                            onChange(message,items)
                        }}
                    />
                    <TextField
                        label="Цена"
                        value={e.cost}
                        onChange={(event) =>
                            {
                                setItems(prevState => ([...prevState.map(f => (f.index !== e.index) ? f : {
                                    cost: event.target.value,
                                    name: f.name,
                                    index:f.index

                                })]))
                                onChange(message,items)
                            }}
                    />
                </div>)
            }
        </Box>
    );
};

