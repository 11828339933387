import React from 'react';
import NewEmailPageV3 from "./NewEmailPageV3";
import {Typography} from "@mui/material";

const NewEmailStep2 = () => {
    return (
        <NewEmailPageV3>
            <Typography component={'h2'} variant={'h5'}>Step 2</Typography>

        </NewEmailPageV3>
    );
};

export default NewEmailStep2;