import React, {FC, PropsWithChildren} from 'react';

const NewEmailForm: FC<PropsWithChildren<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>>> = ({
                                                                                                                                      children,
                                                                                                                                      ...props
                                                                                                                                  }) => {
    return (
        <form noValidate={true} {...props} style={{width: '100%', marginTop: 1}}>
            {children}
        </form>
    );
};

export default NewEmailForm;