import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserStore from "./Storage/UserStore";
import {SnackbarProvider} from "notistack";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

interface State {
    user: UserStore,
}
export const user = new UserStore();
export const Context = createContext<State>({
    user,
})
root.render(
    <SnackbarProvider>
        <App />
    </SnackbarProvider>

);