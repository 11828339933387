import React, {useEffect, useState} from 'react';
import MainComponent from "../Components/MainComponent";
import {Button, Paper, Stack, TextField, Typography} from "@mui/material";
import TitleRow from "../Components/Title";
import {ICustomer} from "../Models/ICustomer";
import CustomersService from "../Service/CustomersService";
import NewCustomer from "../Components/Dialogs/NewCustomer";
import {useNavigate} from "react-router-dom";
import Errorhandler from "../errorHendler";
import CustomerTables from "../Components/ui/tables/CustomerTables";

const CustomersPage = () => {

    const [customers,setCustomers]=useState<ICustomer[]>([]);
    const [allCustomers,setAllCustomers]=useState<undefined|number>(undefined)


    const[page,setPage]=useState(0)
    const[openForm,setOpenForm]=useState(false)

    useEffect(()=>{
        loadingCustomers().then();
    },[])

    const loadingCustomers=async (q?:string)=>{
        try{
            const list=await CustomersService.fetchCustomers(page,q);
            if(q!==undefined){
                setCustomers(list.data)
            }else
            {
                if (list.data.length>0){
                    setPage(prevState => prevState+1)
                    setCustomers(prevState => [...prevState,...list.data])
                }
            }
        }catch (e){
            alert(`error Customer Page axios ${Errorhandler.errorMessage(e)}`)
        }
    }
    const getAllCustomers=async ()=>{
        try{
            const count=await CustomersService.customerCount()
            setAllCustomers(count.data)
        }catch (e) {
            alert(`error Customer Page axios ${Errorhandler.errorMessage(e)}`)
        }
    }


    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Клиенты`} actions={
                    <Stack direction={"row"}>
                        <div>
                            Всего получателей:
                            {
                                (allCustomers===undefined)&&<Button onClick={getAllCustomers}>Получить количество</Button>
                            }
                            {
                                (allCustomers!==undefined)&&<Typography variant={"button"}> {allCustomers}</Typography>
                            }
                        </div>
                        <Button variant="contained" onClick={()=>setOpenForm(true)}>Новый Клиент</Button>
                    </Stack>
                }/>
            </React.Fragment>
            <Paper sx={{mt:2,p:2}}>
                <TextField
                    id="search-customer"
                    label="Поиск"
                    variant="outlined"
                    onChange={(e)=>{
                        setPage(0)
                        loadingCustomers(e.target.value);
                    }}

                />
                <CustomerTables customers={customers}/>
                <Button onClick={()=>loadingCustomers()}>Еще</Button>
            </Paper>
            <NewCustomer
                handleClose={()=>setOpenForm(false)}
                handleCreate={
                (customer)=>{
                    setOpenForm(false);
                    setCustomers(prevState => [customer,...prevState])
                }
            }
                open={openForm}/>
        </MainComponent>
    );
};

export default CustomersPage;